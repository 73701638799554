import './index.less';

import Vue from 'vue';
import Component from 'vue-class-component';
import { dictionaryService, IDictionary } from '@/app/apiServices/dictionary/public-api';
import { IdCardToBirthday, IdCardValid } from '@/core/utils/IdCard/public-api';
import helperService from '@/app/apiServices/helper/helperService';
import { CheckBabyInfoInput, CheckBabyInfoPageDto, ICheckBabyInfoResult } from '@/app/apiServices/helper/public-api';
import { reentryMessage, requestError } from '@/config/message';
import zsRefChildGardenService from '@/app/apiServices/zsRefChildGarden/zsRefChildGardenService';
import { isNullOrEmpty } from '@/core/utils';
import storage from '@/core/utils/storage';
//import date from '@/core/utils/date';
@Component
export default class childBaseTS extends Vue {
    formModel = new CheckBabyInfoInput();
    //证件类型
    zjlxListDic: IDictionary[] = [];
    //是否显示市登记编号
    isShowRegNumber = false;
    //页面是否禁止操作
    pageDisabled = false;
    formRule = {
        id: [
            { required: true, message: "请输入市登记编号！", trigger: "blur" },
            {
                type: "string",
                min: 10,
                max: 10,
                message: "请填写上海市适龄幼儿入园信息登记表上的十位数登记编号！",
                trigger: "blur",
            },
        ],
        name: [{ required: true, message: "请输入幼儿姓名！", trigger: "blur" }],
        cardType: [
            { required: true, message: "请选择幼儿证件类型！", trigger: "change" },
        ],
        cardNumber: [
            { required: true, message: "请输入幼儿证件号码！", trigger: "blur" },
            {
                validator: (rule: any, value: any, callback: any) => {
                    if (this.formModel.cardType == "ZJLX_sfz") {
                        if (value.length == 18) {
                            callback();
                        } else {
                            callback(new Error("请输入18位幼儿证件号码！"));
                        }
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
            {
                validator: (rule: any, value: any, callback: any) => {
                    if (this.formModel.cardType == "ZJLX_sfz") {
                        const gbSFZValidator = IdCardValid(value);
                        if (gbSFZValidator === true) {
                            callback();
                        } else {
                            callback(new Error(`${gbSFZValidator}`));
                        }
                    } else {
                        callback();
                    }
                },
                trigger: "blur",
            },
        ],
        birthday: [{ required: true, message: "请选择幼儿出生日期!", type: "date", trigger: "blur" }],
    };

    //是否显示市登记编号
    get showRegNumberInput() {
        if (this.isShowRegNumber) {
            return true;
        }
        else {
            const { xB_StartDate, xB_EndDate } = this.$store.getters.GET_ZsDateRange;

            // const startDate = new Date(date.formatD(xB_StartDate));           
            // const endDate = new Date(date.formatD(xB_EndDate))

            const startDate = new Date(xB_StartDate);
            const endDate = new Date(xB_EndDate);

            if (this.formModel.cardType == "ZJLX_sfz" && !isNullOrEmpty(this.formModel.cardNumber)) {
                const birthdayStr = IdCardToBirthday(this.formModel.cardNumber) + "T00:00:00";
                const birthday = new Date(birthdayStr);    
                console.log(birthday);     
                
               
                console.log(new Date(IdCardToBirthday(this.formModel.cardNumber)));            
                
                return birthday >= startDate && birthday <= endDate;                
            }
            if (this.formModel.cardType !== "ZJLX_sfz") {
                return this.formModel.birthday >= startDate && this.formModel.birthday <= endDate;
                // return this.formModel.birthday >= new Date(xB_StartDate) && this.formModel.birthday <= new Date(xB_EndDate);
            }
        }
    }

    inputOnBlurChange() {
        document.documentElement.scrollTop = 0;
    }

    cardTypeChange() {
        const { formModel }: any = this.$refs;
        formModel.validateField("cardNumber");
    }

    toPage(childType: number) {
        //本市
        if (childType == 1) {
            this.$router.push(`/thisCity/childInformation`);
        }
        //非本市
        else {
            this.$router.push(`/notThisCity/parentInfomation`);
        }
    }

    nextOnClick() {
        const { formModel }: any = this.$refs;
        formModel.validate((valid: boolean) => {
            if (valid) {
                const { encryptId }: any = this.$store.getters.GET_CurrentUser;
                this.formModel.encryptIdentity = encryptId;

                const childId: any = storage.GET_ActiveChildId();
                if (childId) {
                    this.formModel.childId = childId;
                }

                helperService
                    .checkBabyInfo(this.formModel)
                    .then((res: any) => {
                        const result: ICheckBabyInfoResult = res["data"]["result"];
                        if (result.verifyResult.success) {
                            const resultData = result.data;

                            const cardTypeName = this.zjlxListDic.find(
                                (u) => u.code == this.formModel.cardType
                            );
                            const data = {
                                childId: resultData.childId,
                                cardTypeName: cardTypeName ? cardTypeName.name : "",
                                cardType: this.formModel.cardType,
                                childName: this.formModel.name,
                                cardNumber: this.formModel.cardNumber,
                                regNumber: this.formModel.id,
                                birthday: resultData.birthday,
                                childType: resultData.childType,
                                gradeCode: resultData.gradeCode
                            };

                            this.$store.dispatch("ACTION_RegistrationInfo", data);

                            //如果有报名记录，先删除原有报名记录再进入下一步
                            if (resultData.hasReportRecord) {

                                this.$dialog.confirm({
                                    title: "提示",
                                    message: reentryMessage
                                }).then(() => {
                                    zsRefChildGardenService.deleteRefChildGardens(resultData.childId).then(() => {
                                        this.toPage(resultData.childType);
                                    }).catch(() => {
                                        this.$toast({ message: requestError, duration: 3000 });
                                        return false;
                                    })
                                }).catch(() => {
                                    // on cancel
                                });
                            }
                            else {
                                this.toPage(resultData.childType);
                            }

                        } else {
                            this.$toast({
                                message: result.verifyResult.message,
                                duration: 3000,
                            });
                        }
                    })
                    .catch(() => {
                        this.$toast({ message: requestError, duration: 3000 });
                    });
            }
        });
    }

    back() {
        storage.DELETE_ActiveChildId();
        this.$router.back() //后退
    }
    async viewLoad() {
        const childId: any = storage.GET_ActiveChildId();
        if (childId > 0) {
            helperService.getCheckBabyInfoPage(childId).then((res: any) => {
                const result: CheckBabyInfoPageDto = res["data"]["result"];
                this.zjlxListDic = result.cardTypeSelect || [];

                this.formModel.cardType = result.cardType;
                this.formModel.cardNumber = result.cardNumber;
                this.formModel.name = result.name;
                this.formModel.id = result.id;
                if (result.cardType !== 'ZJLX_sfz') {
                    this.formModel.birthday = new Date(result.birthday);
                }

                this.isShowRegNumber = result.showRegNumberInput;
                this.pageDisabled = result.pageDisabled;
            })
        }
        else {
            this.zjlxListDic = await dictionaryService.getZjlxList();
            //默认身份证
            this.formModel.cardType = "ZJLX_sfz";
        }
    }
    mounted() {
        this.viewLoad();
    }
}